
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="item" class="col-12">
      <h5 class="mb-3">Program Details</h5>
      <div class="row g-4">
        <div class="col-md-12">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <div class="row">
                <div class="col-10 col-sm-8">
                  <div class="d-flex align-items-center justify-content-start mb-3">
                    <div class="">
                      <div class=""><strong>Program Id:</strong> <span>{{ item.friendly_id }}</span></div>
                    </div>
                  </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <div class="dropdown dropstart no-arrow">
                    <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <button @click.prevent="popupModalShow = true" class="dropdown-item"
                        type="button"> Update Program</button>
                      </li>
                      <li>
                        <button @click.prevent="deleteItem()" class="dropdown-item"
                        type="button"> Delete Program </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-sm-6">
                      <label class="small">Title</label>
                      <h6 class="mb-0">{{ item.title }}</h6>
                    </div>
                    <div class="col-sm-6">
                      <label class="small">Total Beneficiaries</label>
                      <h6 class="mb-0">{{ item.beneficiaries_count }}</h6>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                      <label class="small">Total Verifications</label>
                      <h6 class="mb-0">{{ item.verifications_count }}</h6>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                      <label class="small">NIN Verifications</label>
                      <h6 class="mb-0">{{ item.nin_verifications_count }}</h6>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                      <label class="small">VIN Verifications</label>
                      <h6 class="mb-0">{{ item.vin_verifications_count }}</h6>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                      <label class="small">BVN Verifications</label>
                      <h6 class="mb-0">{{ item.bvn_verifications_count }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <beneficiaries :program="item"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal size="lg" v-model="popupModalShow" hide-footer centered
    title="Update program"
    title-class="fs-6" @hide="initializeAll()">
    <program-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editItem="item" :editMode="true" @updatedItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import ProgramForm from '@/components/forms/ProgramForm.vue'
import Beneficiaries from './Beneficiaries.vue'

export default {
  name: "program-show",
  components:{
    IsLoading,
    ProgramForm,
    Beneficiaries
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      item: null,
    }
  },
  watch: {
    '$route.params.programId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.item, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/programs/${this.$route.params.programId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      this.confirmDelete({
        text: "You want to delete program permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/programs/${this.item.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/programs'})
            }
          })
        }
      });
    },
    showProfileImg(image){
      const data = { index: 0, visible: true, files: [ this.absoluteUrl(image)] }
      this.$store.commit('TOGGLE_LIGHT_BOX', data)
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

